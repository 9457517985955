<template>
<div class="main-inner-blk">
  <div class="re-register-modal">
    <Register></Register>
  </div>
</div>
</template>

<script>
import Register from "@/components/Register";
export default {
  name: "ReRegister",
  components: {Register}
}
</script>

<style scoped lang="scss">
.re-register-modal{
  margin: 10px 10px;
  height: calc(100vh - 100px);
  border-radius: 5px;
  font-family: 'Muli',sans-serif;
  overflow-y: scroll;
  background-color: #904597;
}
</style>