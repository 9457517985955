<template>
  <div class="main-inner-blk">
    <v-card class="vehicle-filter-blk" elevation="3">
      <Filters :placeHolder="'Search Vehicle Number'" @get-filter="getFilters"></Filters>
    </v-card>

    <div class="vehicle-modal">
      <div class="vehicle-list-modal">
        <VehicleList
          @load-more-vehicles="loadMoreVehicles"
          :page="page"
          :items="items"
          :vehicleList="vehicleList"
          @insert-vehicle="addVehicle"
          @select-vehicle="selectVehicle"
          :vehicleDetails="vehicleDetails">
        </VehicleList>
      </div>
      <div class="vehicle-content-modal">
        <v-tabs background-color="black" color="#facc15" slider-color="#facc15" dark v-model="tabs">
          <v-tab href="#cab-profile-tab" :disabled="tabs === 'cab-insert-tab'">Profile</v-tab>
          <v-tab href="#cab-insert-tab" v-if="tabs === 'cab-insert-tab'">{{ textName }} Vehicle</v-tab>
          <v-tab href="#cab-document-tab" :disabled="tabs === 'cab-insert-tab'">Documents</v-tab>
          <v-tab href="#cab-remarks-list" :disabled="tabs === 'cab-insert-tab'">List</v-tab>
          <v-tabs-items v-model="tabs" class="vehicle-tab-items">
            <v-tab-item id="cab-insert-tab">
              <VehicleInsert @cancel-insert="cancelInsert" :tabName="tabs" :vehicleDetails="vehicleDetails" :editStatus="editStatus" @vehicle-updated="getVehicleDetails({ loadList: true })">
              </VehicleInsert>
            </v-tab-item>
            <v-tab-item id="cab-profile-tab">
              <VehicleProfile :vehicleDetails="vehicleDetails" @edit-vehicle="editVehicle" @vehicle-updated="getVehicleDetails({ loadList: true })"> </VehicleProfile>
            </v-tab-item>
            <v-tab-item id="cab-document-tab">
              <VehicleDocument :vehicleDetails="vehicleDetails"> </VehicleDocument>
            </v-tab-item>
            <v-tab-item id="cab-remarks-list">
              <VehicleRemarksList :vehicleDetails="vehicleDetails"></VehicleRemarksList>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import VehicleList from "@/components/vehicle-management/vehicle-list/VehicleList";
import VehicleInsert from "@/components/vehicle-management/vehicle-insert/VehicleInsert";
import VehicleDocument from "@/components/vehicle-management/vehicle-document/VehicleDocument";
import axios from "@/axios/axios-global";
import VehicleProfile from "@/components/vehicle-management/vehicle-profile/VehicleProfile";
import Loading from "@/components/common/Loading";
import VehicleRemarksList from "@/components/vehicle-management/vehicle-remarks-list/VehicleRemarksList";

export default {
  name: "VehicleDashboard",
  components: { Loading, VehicleProfile, VehicleInsert, VehicleList, Filters, VehicleDocument, VehicleRemarksList },
  data: () => {
    return {
      vehicleList: [],
      tabs: "cab-profile-tab",
      textName: "Add",
      editStatus: false,
      vehicleDetails: {},
      loading: false,
      filters: "",
      page: 1,
      items: 20,
    };
  },
  mounted() {
    this.getVehicleList(this.filters);
  },
  methods: {
    getFilters(data) {
      this.filters = data;
      this.getVehicleList(this.filters);
      // this.getVehicleDetails({loadList:false})
    },
    async getVehicleDetails(data) {
      if (data.loadList) {
        this.getVehicleList({ searchValue: "" });
      }
      if (this.vehicleDetails.token) {
        this.loading = true;
        const response = await axios.post("vehicle/details", { vehicleId: this.vehicleDetails.token });
        this.vehicleDetails = response.data;
        this.loading = false;
        this.tabs = "cab-profile-tab";
      }
    },
    async getVehicleList(data) {
      this.loading = true;
      this.vehicleList = [];
      this.vehicleDetails = {}
      const response = await axios.post("vehicle/list", { search: data.searchValue?data.searchValue:"", page: this.page, items: this.items });
      this.loading = false;
      this.vehicleList = this.page === 1 ? response.data : [...this.vehicleList, ...response.data];
      if (this.vehicleList.length > 0) {
        if (this.vehicleDetails.token) {
          this.vehicleDetails = this.vehicleList.find((v) => v.token === this.vehicleDetails.token);
        } else {
          this.vehicleDetails = this.vehicleList[0];
        }
        this.getVehicleDetails({ loadList: false });
      }
    },
    addVehicle() {
      this.tabs = "cab-insert-tab";
      this.editStatus = false;
      if (this.textName === "Edit") {
        this.tabs = "cab-profile-tab";
        setTimeout(() => {
          this.tabs = "cab-insert-tab";
        }, 0);
      }
      this.textName = "Add";
    },
    cancelInsert() {
      this.tabs = "cab-profile-tab";
    },
    selectVehicle(data) {
      this.tabs = "cab-profile-tab";
      this.vehicleDetails = data;
      this.getVehicleDetails({ loadList: false });
    },
    editVehicle() {
      this.tabs = "cab-insert-tab";
      this.editStatus = true;
      this.textName = "Edit";
    },
    getVehicle() {
      this.tabs = "cab-profile-tab";
    },
    loadMoreVehicles() {
      this.page++;
      this.getVehicleList(this.filters);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./vehicleDashboard";
</style>
