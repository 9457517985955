<template>
  <v-card class="driver-bulk-modal">
    <div class="bulk-modal">
      <div class="button-header">
        <div>
        <v-btn color="#087c0d" @click="uploadExcel" v-if="buttonCheck === false">Upload</v-btn>
        <v-btn color="red" @click="cancelUpload">Cancel</v-btn>
        </div>
        <span v-if="buttonCheck === true" class="error-blk"><i class="fas fa-exclamation"></i>Some Details are missing, fill the red marked columns correctly and upload</span>
      </div>
      <div class="bulk-content-modal">
        <div class="content-header">
          <span class="header font-blk">Name</span>
          <span class="header font-blk">Number</span>
          <span class="header font-blk">Additional Contact</span>
          <span class="header font-blk">Licence No</span>
          <span class="header font-blk">Licence Exp Date</span>
        </div>

        <div class="bulk-content">
          <div class="driver-bulk-content" v-for="(data,index) in driverData" :key="index">
            <span class="header" :class="{'no-data':!data.name}">{{ data.name }}</span>
            <span class="header" :class="{'no-data':!data.mobile || data.mobile.length !== 10 || isNaN(data.mobile) === true}">{{ data.mobile }}</span>
            <span class="header" :class="{'no-data':!data.contact}">{{ data.contact }}</span>
            <span class="header" :class="{'no-data':!data.licenceNo}">{{ data.licenceNo }}</span>
            <span class="header" :class="{'no-data':!data.expdate}">{{ data.expdate }}</span>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </v-card>
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import * as moment from 'moment'

export default {
  name: "BulkDriverView",
  components: {Loading},
  props: {
    driverData: Array
  },
  data: () => {
    return {
      oldListLength: 0,
      newListLength: 0,

      newFormatData: [],
      buttonCheck: false,
      loading: false,
    }
  },
  mounted() {
    this.oldListLength = this.driverData.length
    this.driverData.map(driver => {
      if (driver.name && driver.mobile && driver.contact && driver.licenceNo && driver.expdate && driver.mobile.length === 10 && isNaN(driver.mobile) === false) {
        this.newFormatData.push({
          name: driver.name,
          mobile: driver.mobile,
          additionalContact: driver.contact,
          licensefront: {no: driver.licenceNo, expdate: moment(driver.expdate).format('YYYYMMDD')}
        })
      }
    })
    this.newListLength = this.newFormatData.length
    if(this.newListLength !== this.oldListLength) {
      this.buttonCheck = true
    }
  },
  methods: {
    async uploadExcel() {
      this.loading = true
      try {
        const response = await axios.post('/router/driverInsert', {driver: this.newFormatData})
        if (response.status === 200) {
          this.loading = false
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000
          });
          this.$emit('insert-bulk',{array:this.newFormatData,idArray:response.data.result})
        } else if (response.status === 206) {
          this.loading = false
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000
          });
        }
      }catch (error) {
        this.loading = false
        // this.$toasted.show(error.response.data, {
        //   position: "bottom-center",
        //   duration: 4000
        // });
      }
    },
    cancelUpload() {
      this.$emit('cancel-bulk')
    }
  }
}
</script>

<style scoped lang="scss">
.driver-bulk-modal {
  padding: 10px;

  .bulk-modal {
    display: flex;
    flex-direction: column;

    .button-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 15px;

      .v-btn {
        margin: 0 5px;
      }
      .error-blk {
        color: red;
        font-weight: bold;
        .fas {
          margin-right: 3px;
        }
      }
    }

    .bulk-content-modal {
      //padding:0  10px 10px  10px;
      max-height: calc(100vh - 300px);
      overflow-y: scroll;

      .content-header {
        display: flex;
        width: 100%;
        border: 1px solid gray;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;

        .header {
          width: 20%;
          padding: 5px;
          border-right: 1px solid gray;
        }

        .font-blk {
          font-weight: bold;
          color: #6a0737;
        }

        :last-child {
          border-right: none;
        }
      }

      .bulk-content {
        display: flex;
        flex-direction: column;
        border: 1px solid gray;
        border-top: none;
        border-bottom: none;

        .driver-bulk-content {
          display: flex;
          width: 100%;
          border-bottom: 1px solid gray;

          .header {
            width: 20%;
            padding: 5px;
            border-right: 1px solid gray;
            word-break: break-all;
          }

          .no-data {
            background-color: #f15d5d;
          }

          :last-child {
            border-right: none;
          }
        }
      }
    }

  }
}
</style>