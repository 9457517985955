<template>
<div class="user-driver-modal">
  <v-card class="user-card-modal" elevation="2" v-if="userReading">
    <span class="card-header-modal">Trip Data - Customer</span>
    <div class="details-blk">
      <div class="start-end-details-blk">
        <div class="image-blk"  v-if="userReading.start">
          <img :src="userReading.start.photo" class="img-blk">
        </div>
        <div class="reading-modal"  v-if="userReading.start">
          <strong>Start Reading</strong>
          <span>{{ userReading.start.reading }}</span>
        </div>
        <div class="reading-modal"  v-if="userReading.start">
          <strong>Start Date</strong>
          <span>{{getTimeDate(userReading.start.date)}}</span>
        </div>
        <div class="reading-modal"  v-if="userReading.start">
          <strong>Start location</strong>
          <span>{{ userReading.start.address }}</span>
        </div>
      </div>
      <div class="start-end-details-blk" >
        <div class="image-blk" v-if="userReading.end">
          <img :src="userReading.end.photo" class="img-blk">
        </div>
        <div class="reading-modal" v-if="userReading.end">
          <strong>End Reading</strong>
          <span>{{ userReading.end.reading }}</span>
        </div>
        <div class="reading-modal" v-if="userReading.end">
          <strong>End Date</strong>
          <span>{{getTimeDate(userReading.end.date)}}</span>
        </div>
        <div class="reading-modal" v-if="userReading.end">
          <strong>End location</strong>
          <span>{{ location }}</span>
        </div>
      </div>
    </div>
  </v-card>

<!--  <v-card class="driver-card-modal" elevation="2" v-if="driverReading">-->
<!--    <span class="card-header-modal">Trip Data - Driver</span>-->
<!--    <div class="details-blk">-->
<!--      <div class="start-end-details-blk">-->
<!--        <div class="image-blk"  v-if="driverReading">-->
<!--          <img :src="driverReading.start.photo" class="img-blk">-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading">-->
<!--          <strong>Start Reading</strong>-->
<!--          <span>{{ driverReading.start.reading }}</span>-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading">-->
<!--          <strong>Start Date</strong>-->
<!--          <span>{{getTimeDate(driverReading.start.date)}}</span>-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading">-->
<!--          <strong>Start location</strong>-->
<!--          <span>{{ driverReading.start.address }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="start-end-details-blk">-->
<!--        <div class="image-blk" v-if="driverReading.end">-->
<!--          <img :src="driverReading.end.photo" class="img-blk">-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading.end">-->
<!--          <strong>End Reading</strong>-->
<!--          <span>{{ driverReading.end.reading }}</span>-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading.end">-->
<!--          <strong>End Date</strong>-->
<!--          <span>{{getTimeDate(driverReading.end.date)}}</span>-->
<!--        </div>-->
<!--        <div class="reading-modal" v-if="driverReading.end">-->
<!--          <strong>End location</strong>-->
<!--          <span>{{ driverReading.end.address }}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </v-card>-->
</div>
</template>

<script>
import * as moment from "moment";

export default {
  name: "UserDriverData",
  props: {
    userReading: Object,
    driverReading: Object,
  },
  data: () => {
    return {
      image: 'https://taski2020.s3.ap-south-1.amazonaws.com/noimage/noreading.jpg?ver=1653366609092',
      image2: 'https://taski2020.s3.ap-south-1.amazonaws.com/trip/1652946879260000/userend.jpg?ver=1653366609092',
      location: 'GGX8+5GG, Seshappa Thota, Madanapalle, Andhra Pradesh 517330, India'
    }
  },
  methods: {
    getTimeDate(data) {
      return moment(data).format('DD-MM-YYYY LT')
    }
  }
}
</script>

<style scoped lang="scss">
.user-driver-modal {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .user-card-modal {
    width: 48%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 0;
    border: 1px solid gray;
  }
  .driver-card-modal {
    width: 48%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 0;
  }

  .card-header-modal {
    font-size: 17px;
    font-weight: bold;
    color: #fa7815;
  }
  .details-blk {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    .start-end-details-blk {
      display: flex;
      flex-direction: column;
      width: 49%;
      .image-blk {
        width: 100%;
        border: 1px solid gray;
        height: 200px;
        overflow: hidden;
        .img-blk{
          width: 100%;
          object-fit: contain;

        }
      }
      .reading-modal {
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        strong {
          color: #fa7815;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>