<template>
  <v-dialog v-model="showModel" max-width="400px">
    <v-card>
      <v-card-title class="headline">
        <slot name="title">{{ title }}</slot>
      </v-card-title>
      <v-card-text>
        <slot name="body">
          <v-textarea v-model="remarks" label="Remarks" rows="3" dense></v-textarea>
        </slot>
      </v-card-text>
      <v-card-actions style="justify-content: flex-end">
        <v-btn color="#ed5e5e" @click="closeModalHandler">Cancel</v-btn>
        <v-btn color="#eea004" @click="confirmActionHandler">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeactivationModal",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close-modal"],
  data() {
    return {
      remarks: this.remarks,
      showModel:this.show
    };
  },
  methods: {
    closeModalHandler() {
      this.showModel = false;
      this.remarks = "";
      this.$emit('dialog-closed')
    },
    confirmActionHandler() {
      this.onConfirm(this.remarks);
    },
  },
};
</script>

<style scoped></style>
