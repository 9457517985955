<template xmlns="http://www.w3.org/1999/html">
  <div class="driver-list-blk">
    <div class="driver-header-blk">
      <v-btn small color="#facc15" @click="addDriver()">Add Driver</v-btn>
      <!--      <div class="driver-button-modal">-->
      <!--        <input ref="driverInsert" type="file"-->
      <!--               accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"-->
      <!--               @change="addMultipleDriver" class="input-blk">-->
      <!--        <v-btn small color="green" @click="$refs.driverInsert.click()">Bulk Upload</v-btn>-->

      <!--        <v-btn small color="blue" title="Sample Excel" @click="downloadSample"><i class="fas fa-download"></i></v-btn>-->
      <!--      </div>-->
    </div>
    <!-- <div class="driver-content-modal" @scroll="onScroll" ref="scrollContainer"> -->
    <div class="driver-content-modal" ref="scrollContainer">
      <div v-if="driverList.length > 0">
        <div class="driver-list-content-blk" v-for="(data, index) in driverList" :key="index" @click="selectDriver(data)" :class="{ 'selected-driver-blk': driverId === data.token }">
          <div class="driver-image-blk">
            <div class="image-outer-blk">
              <!--              <img :src="data.image" class="driver-img-blk">-->
              <img :src="data.image" class="driver-img-blk" />
            </div>
          </div>
          <div class="driver-details-blk">
            <span class="driver-name-blk">{{ data.name }}</span>
            <span>{{ data.mobile }}</span>
          </div>
          <span class="driver-status-blk" :style="!data.trash ? 'color:green' : 'color:red'">{{ !data.trash ? "Active" : "Inactive" }}</span>
        </div>
      </div>
      <div v-else class="no-driver-blk">
        <span>No Driver, Please Add</span>
      </div>
    </div>
    <v-dialog width="800" v-model="bulkDialogView">
      <BulkDriverView :driverData="driverData" v-if="bulkDialogView" @cancel-bulk="cancelUpload" @insert-bulk="uploadBulk"></BulkDriverView>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import * as moment from "moment";
import BulkDriverView from "@/components/driver-management/BulkDriverView";
import VueExcel from "@/components/common/VueExcel";
export default {
  name: "DriverDataList",
  components: { BulkDriverView },
  props: {
    driverList: Array,
    id: String,
    page: Number,
    items: Number,
  },
  data: () => {
    return {
      driverId: "",
      bulkDialogView: false,
      driverData: [],
    };
  },
  watch: {
    id() {
      this.driverId = this.id;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.driverList.length > 0) {
        this.driverId = this.driverList[0].id;
      }
    }, 300);
  },
  methods: {
    selectDriver(data) {
      this.driverId = data.id;
      this.$emit("select-driver", data);
    },
    // onScroll() {
    //   const container = this.$refs.scrollContainer;
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
    //     this.$emit("load-more");
    //   }
    // },
    addDriver() {
      this.$emit("add-driver");
    },
    addMultipleDriver(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: "binary",
            cellDates: true,
            cellNF: false,
            cellText: false,
          });
          // /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // console.log(XLSX.utils.sheet_to_json(ws, {header: 0}))
          // /* Convert array of arrays */
          this.driverData = XLSX.utils.sheet_to_json(ws, { header: 0 });
          this.driverData.map((data) => {
            data.expdate = moment(data.expdate).format("YYYY-MM-DD");
            data.mobile = data.mobile.toString();
          });
          this.bulkDialogView = true;
          this.$refs.driverInsert.value = "";
        };
        reader.readAsBinaryString(this.file);
      }
    },
    downloadSample() {
      let sampleArray = [];
      sampleArray.push({
        name: "name",
        mobile: "mobile",
        contact: "contact",
        licenceNo: "licenceNo",
        expdate: "expdate",
      });
      sampleArray.push({
        name: "sample",
        mobile: "sample",
        contact: "sample",
        licenceNo: "sample",
        expdate: "01-01-2022",
      });
      VueExcel.methods.downloadExcelSheet(sampleArray, "Sample Excel", { skipHeader: true });
    },
    cancelUpload() {
      this.bulkDialogView = false;
      this.driverData = [];
    },
    uploadBulk(data) {
      this.bulkDialogView = false;
      console.log(data);
      data.array.map((driver) => {
        const index = data.idArray.findIndex((item) => driver.mobile === item.mobile);
        if (index !== -1) {
          this.$emit("insert-driver", {
            id: data.idArray[index].id,
            name: driver.name,
            mobile: driver.name,
            image: "https://taski2020.s3.ap-south-1.amazonaws.com/noimage/noimage.png",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./driverList";
</style>
