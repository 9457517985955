<template>
  <div class="document-insert-content-modal">
    <div class="document-upload-modal" v-if="driverDetails.token !== 0">
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="photoUpload" @change="insertPhoto" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.photoUpload.click()" v-if="photoImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="photoImage !== ''">
          <img :src="photoImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Photo</span>
          <div class="buttons" v-if="photoImage">
            <v-chip readonly outlined :color="documents.photo.verified ? 'green' : 'red'">{{ documents.photo.verified ? "Verified" : "Rejected" }}</v-chip>
            <v-btn small color="red" @click="validateDocument({ doctype: 'photo', verified: 0 })" v-if="photoImage !== '' && documents.photo.verified">Reject<i class="fas fa-times"></i></v-btn>
            <v-btn small color="green" @click="validateDocument({ doctype: 'photo', verified: 1 })" v-if="photoImage !== '' && !documents.photo.verified">Verify<i class="fas fa-check"></i></v-btn>
            <v-btn small color="#83b9e5" @click="$refs.photoUpload.click()" v-if="photoImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="idProof" @change="insertIdProof" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.idProof.click()" v-if="idImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="idImage !== ''">
          <img :src="idImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>ID Proof</span>
          <div class="buttons" v-if="idImage">
            <v-chip readonly outlined :color="documents.idproof.verified ? 'green' : 'red'">{{ documents.idproof.verified ? "Verified" : "Rejected" }}</v-chip>
            <v-btn small color="red" @click="validateDocument({ doctype: 'idproof', verified: 0 })" v-if="idImage !== '' && documents.idproof.verified">Reject<i class="fas fa-times"></i></v-btn>
            <v-btn small color="green" @click="validateDocument({ doctype: 'idproof', verified: 1 })" v-if="idImage !== '' && !documents.idproof.verified">Verify<i class="fas fa-check"></i></v-btn>
            <v-btn small color="#83b9e5" @click="$refs.idProof.click()" v-if="idImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <!--      <v-card class="photo-insert-modal" elevation="2" >-->
      <!--        <input accept="image/*" ref="panProof" @change="insertPan" type="file" class="input-blk">-->
      <!--        <div class="image-upload-modal" @click="$refs.panProof.click()" v-if="panImage === ''">-->
      <!--          <i class="fas fa-images"></i>-->
      <!--          <span>Upload Image</span>-->
      <!--        </div>-->
      <!--        <div class="image-upload-modal" v-if="panImage !== ''">-->
      <!--          <img :src="panImage" class="image-view-blk">-->
      <!--        </div>-->
      <!--        <span class="image-name-blk">Pan Card</span>-->
      <!--      </v-card>-->
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="licenceFront" @change="insertLicenceFront" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.licenceFront.click()" v-if="licenceFrontImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceFrontImage !== ''">
          <img :src="licenceFrontImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Licence Front</span>
          <div class="buttons" v-if="licenceFrontImage">
            <v-chip readonly outlined :color="documents.licensefront.verified ? 'green' : 'red'">{{ documents.licensefront.verified ? "Verified" : "Rejected" }}</v-chip>
            <v-btn small color="red" @click="validateDocument({ doctype: 'licensefront', verified: 0 })" v-if="licenceFrontImage !== '' && documents.licensefront.verified"
              >Reject<i class="fas fa-times"></i
            ></v-btn>
            <v-btn small color="green" @click="validateDocument({ doctype: 'licensefront', verified: 1 })" v-if="licenceFrontImage !== '' && !documents.licensefront.verified"
              >Verify<i class="fas fa-check"></i
            ></v-btn>
            <v-btn small color="#83b9e5" @click="$refs.licenceFront.click()" v-if="licenceFrontImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="licenceBack" @change="insertLicenceBack" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.licenceBack.click()" v-if="licenceBackImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="licenceBackImage !== ''">
          <img :src="licenceBackImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Licence Back</span>
          <div class="buttons" v-if="licenceBackImage">
            <v-chip readonly outlined :color="documents.licenseback.verified ? 'green' : 'red'">{{ documents.licenseback.verified ? "Verified" : "Rejected" }}</v-chip>
            <v-btn small color="red" @click="validateDocument({ doctype: 'licenseback', verified: 0 })" v-if="licenceBackImage !== '' && documents.licenseback.verified"
              >Reject<i class="fas fa-times"></i
            ></v-btn>
            <v-btn small color="green" @click="validateDocument({ doctype: 'licenseback', verified: 1 })" v-if="licenceBackImage !== '' && documents.licenseback.verified"
              >Verify<i class="fas fa-check"></i
            ></v-btn>
            <v-btn small color="#83b9e5" @click="$refs.licenceBack.click()" v-if="licenceBackImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="policeClearance" @change="insertPoliceClearance" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.policeClearance.click()" v-if="policeClearanceImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="policeClearanceImage !== ''">
          <img :src="policeClearanceImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Police Clearance</span>
          <div class="buttons" v-if="policeClearanceImage">
            <v-chip readonly outlined :color="documents.policeclearance.verified ? 'green' : 'red'">{{ documents.policeclearance.verified ? "Verified" : "Rejected" }}</v-chip>
            <v-btn small color="red" @click="validateDocument({ doctype: 'policeclearance', verified: 0 })" v-if="policeClearanceImage !== '' && documents.policeclearance.verified"
              >Reject<i class="fas fa-times"></i
            ></v-btn>
            <v-btn small color="green" @click="validateDocument({ doctype: 'policeclearance', verified: 1 })" v-if="policeClearanceImage !== '' && !documents.policeclearance.verified"
              >Verify<i class="fas fa-check"></i
            ></v-btn>
            <v-btn small color="#83b9e5" @click="$refs.policeClearance.click()" v-if="policeClearanceImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="no-driver-document">No data Available</div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import * as moment from "moment";
import Loading from "@/components/common/Loading";

export default {
  name: "DocumentInsert",
  components: { Loading },
  props: {
    driverDetails: Object,
  },
  data: () => {
    return {
      photoImage: "",
      idImage: "",
      // panImage: '',
      licenceFrontImage: "",
      licenceBackImage: "",
      policeClearanceImage: "",
      documents: "",
      loading: false,
    };
  },
  // licenceNo: String,
  // expDate: String,
  // documents: Object,
  mounted() {
    this.getDriverDetails();
  },
  methods: {
    async getDriverDetails() {
      if (this.driverDetails.token) {
        this.loading = true;
        const response = await axios.post("driver/details", { driverId: this.driverDetails.token });
        this.loading = false;
        this.documents = response.data.document;
        this.photoImage = this.documents.photo.image;
        this.idImage = this.documents.idproof.image;
        this.licenceFrontImage = this.documents.licensefront.image;
        this.licenceBackImage = this.documents.licenseback.image;
        this.policeClearanceImage = this.documents.policeclearance.image;
      }
    },
    async validateDocument(document) {
      document.token = this.driverDetails.token;
      const response = await axios.post("driver/document/verify", document);
      console.log("response", response);
      if (response && response.status === 200) {
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
        this.getDriverDetails();
      } else {
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
    async insertPhoto(event) {
      let files = event.target.files[0];
      // this.photoImage = URL.createObjectURL(files)
      this.loading = true;
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "photo",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        console.log(response);
        this.loading = false;
        if (response.status === 200) {
          this.photoImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertIdProof(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.idImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "idProof",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        console.log(response);
        this.loading = false;
        if (response.status === 200) {
          this.idImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    // async insertPan(event) {
    //   let files = event.target.files[0]
    //   this.panImage = URL.createObjectURL(files)
    // },
    async insertLicenceFront(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.licenceFrontImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "licenceFront",
          licenceNo: this.licenceNo,
          expData: moment(this.expDate).format("YYYYMMDD"),
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        console.log(response);
        this.loading = false;
        if (response.status === 200) {
          this.licenceFrontImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertLicenceBack(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.licenceBackImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "licenceBack",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        console.log(response);
        this.loading = false;
        if (response.status === 200) {
          this.licenceBackImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertPoliceClearance(event) {
      let files = event.target.files[0];
      this.loading = true;
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          driverId: this.driverDetails.token,
          imageName: "policeClearance",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("driver/document/update", formData);
        console.log(response);
        this.loading = false;
        if (response.status === 200) {
          this.policeClearanceImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./documentInsert";
</style>
