<template>
  <div class="driver-list">
    <v-data-table :headers="headers" :items="getRemarks" class="elevation-1" :items-per-page="5">
      <template v-slot:item.status="{ item }">
        <v-chip :color="!item.action ? 'green' : 'red'" small dark>{{ !item.action ? "Activated" : "Deactivated" }}</v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "DriversRemarksList",
  props: {
    driverDetails: Object,
  },
  data: () => {
    return {
      headers: [
        { text: "Employee Name", align: "start", sortable: true, value: "remarksBy.name" },
        { text: "Reason", value: "reason" },
        { text: "Date", value: "date" },
        { text: "Status", value: "status", sortable: true },
      ],
    };
  },
  computed:{
    getRemarks() {
      if (this.driverDetails && this.driverDetails.reason && this.driverDetails.reason.length > 0) {
       this.driverDetails.reason.map((remark) => {
          remark.date = remark.date ? moment(remark.date).format("YYYY-MM-DD HH:mm:ss") : "Not found";
          return remark;
        });
        return this.driverDetails.reason
      }else{
        return []
      }
    },
  },
};
</script>

<style scoped lang="scss">
.driver-list {
  padding: 10px;
}
</style>
