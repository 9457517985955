<template>
  <div class="main-inner-blk">
    <v-card class="assign-filter-blk" elevation="3">
      <Filters @get-filter="getFilters" :filterData="'toBeAssigned'" :placeHolder="'Search ID/User'"></Filters>
    </v-card>
    <div class="trip-list-blk">
      <div v-if="tripList.length > 0">
        <div class="trip-list-header-blk">
          <span class="sl-no-blk header-content-blk">Sl No</span>
          <span class="id-blk header-content-blk"> Trip ID</span>
          <span class="user-blk header-content-blk">User</span>
          <span class="corporate-blk header-content-blk">Corporate</span>
          <span class="time-blk header-content-blk">PickUp</span>
          <span class="category-blk header-content-blk">Category</span>
          <span class="details-blk header-content-blk">Assign Details</span>
          <span class="driver-blk">Driver</span>
          <span class="reject-blk">Copy</span>
          <!-- <div class="action-blk">Actions</div> -->
        </div>
        <div class="trip-content-blk" v-for="(data, index) in tripList" :key="index">
          <span class="sl-no-blk list-content-blk">{{ index + 1 }}</span>
          <span class="id-blk list-content-blk id-content-blk" @click="showDetails(data.bigId)">{{ data.id }}</span>
          <div class="user-blk content-blk list-content-blk">
            <span>{{ data.user }}</span>
            <span class="">{{ data.mobile }}</span>
            <v-tooltip bottom color="#b02604" max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <span class="source-blk" v-bind="attrs" v-on="on">{{ data.source }}</span>
              </template>
              <span>{{ data.source }}</span>
            </v-tooltip>
          </div>
          <div class="corporate-blk content-blk list-content-blk">
            <span>{{ data.corporate }}</span>
            <span>{{ data.branch }}</span>
            <span v-if="data.etsDetails" class="marshal-row">{{ data.etsDetails.hasMarshal === 0 ? "No" : "" }} Marshal Required</span>
          </div>
          <div class="time-blk content-blk list-content-blk">
            <span>{{ getTime(data.pickUpTime) }}</span>
            <span>{{ getTimer(data.pickUpTime) }}</span>
          </div>
          <div class="category-blk list-content-blk">
            <span>{{ data.category }}</span>
            <strong v-if="data.tripType">{{ data.tripType }}</strong>
          </div>
          <div class="details-blk content-blk list-content-blk">
            <span v-if="data.driverDetails !== null">{{ data.driverDetails.name }} - {{ data.driverDetails.mobile }} - {{ data.vehicle }}</span>
            <div class="details-button-blk" v-if="data.status.id === 4 || data.status.id === 5 || data.status.id === 6">
              <v-btn x-small color="#e97701" @click="usAssignDriver(data, index)" v-if="data.status.id === 4">un-assign</v-btn>
              <v-btn x-small color="blue" @click="startTrip(data, index)" v-if="!enableETS">Start Trip</v-btn>
            </div>
          </div>
          <div class="driver-blk">
            <v-text-field v-model="data.driver" placeholder="Driver" label="Driver" dense outlined hide-details @input="getDriverDetails(data, index)" maxlength="10"></v-text-field>
          </div>
          <div class="reject-blk">
            <v-btn color="green" text small title="Copy" @click="copyMessage(data)" class="whatsapp-blk"><i class="fab fa-whatsapp-square"></i></v-btn>
          </div>
          <!-- <div class="action-blk">
            <div class="justify-space-around d-flex" v-if="data.status.id <= 2">
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon size="15px" color="red" v-bind="attrs" v-on="on" @click="getTripInfo(data)"> fas fa-times-circle </v-icon>
                </template>
                <span>Reject</span>
              </v-tooltip>
            </div>
            <div v-else class="justify-space-around d-flex">---</div>
          </div> -->
        </div>
      </div>
      <div v-else class="no-trip-blk">
        <span>No Trips To Assign</span>
      </div>
    </div>
    <v-dialog v-model="assignDialog" width="800" persistent>
      <v-card>
        <DriverAssign
          :driverDetails="driverDetails"
          :bigId="bigId"
          @close-dialog="closeAssignDialog"
          v-if="assignDialog"
          :driverNumber="driverNumber"
          @assign-driver="driverAssign"
          :modelList="modelList"
          :currentCity="currentCity"
          :currentState="currentState"></DriverAssign>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDialogStatus" width="800" persistent>
      <v-card>
        <DriverCabInsert
          @close-add-dialog="closeAddDialog"
          :driverNumber="driverNumber"
          :bigId="bigId"
          @assign-driver="driverCabAssign"
          :name="driverName"
          :disableStatus="disableStatus"
          :modelList="modelList"
          :currentCity="currentCity"
          :currentState="currentState"
          v-if="addDialogStatus"></DriverCabInsert>
      </v-card>
    </v-dialog>
    <v-navigation-drawer v-model="drawer" temporary width="1200" absolute>
      <TripDetails :tripId="tripId" v-if="drawer"></TripDetails>
    </v-navigation-drawer>
    <v-dialog width="500" v-model="dialogView">
      <v-card class="card-view-modal">
        <span>{{ message }}</span>
        <div class="card-button-blk">
          <v-btn small color="#eea004" @click="closeVendorDialog">OK</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="startDialog" width="650" persistent>
      <StartEndDialog @close-dialog="closeDialog" :bigId="statusBigId" @start-end-trip="startEndTrip" :status="status" v-if="startDialog"></StartEndDialog>
    </v-dialog>
    <Loading v-if="loading"></Loading>
    <Confirm ref="confirm"></Confirm>
    <DeactivationModal v-if="remarksModel" title="Reject" :show.sync="remarksModel" :onConfirm="rejectTrip"> </DeactivationModal>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import axios from "@/axios/axios-global";
import * as moment from "moment";
import DriverAssign from "@/components/assign/driver-assign/DriverAssign";
import DriverCabInsert from "@/components/assign/driver-cab-insert/DriverCabInsert";
import Loading from "@/components/common/Loading";
import TripDetails from "@/components/Trips/trip-details/TripDetails";
import Confirm from "@/components/common/Confirm";
import StartEndDialog from "@/components/Trips/trips-view/StartEndDialog";
import DeactivationModal from "../../modal/DeactivationModal.vue";
export default {
  name: "ToBeAssigned",
  components: { Confirm, TripDetails, Loading, DriverCabInsert, DriverAssign, Filters, StartEndDialog, DeactivationModal },
  data: () => {
    return {
      remarksModel: false,
      tripList: [],
      tripId: 0,
      tripIndex: "",
      assignDialog: false,
      driverDetails: "",
      addDialogStatus: false,
      driverNumber: "",
      bigId: "",
      driverName: "",
      disableStatus: false,
      dialogView: false,
      loading: false,
      drawer: false,
      date: "",
      timeout: "",
      modelList: [],
      currentCity: 0,
      currentState: 0,
      filters: "",
      startDialog: false,
      statusBigId: 0,
      status: "",
      enableETS: "",
      rejectionReason: null,
      message: "",
      tripInfo: {},
    };
  },
  mounted() {
    this.getTripData({ date: this.date });
    this.getAssignFilters();
    this.enableETS = JSON.parse(localStorage.getItem("enableETS"));
  },
  methods: {
    getFilters(data) {
      this.filters = data;
      this.getTripData(this.filters);
      this.$emit("get-all-filters", this.filters);
    },
    async getAssignFilters() {
      const response = await axios.get("settings/model/list");
      this.modelList = response.data;
    },
    async getTripData(data) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.loading = true;
        const response = await axios.post("/trip/toAssign", {
          state: data.state,
          search: data.searchValue,
          city: data.city,
          date: data.date,
          branch: data.branch,
        });
        this.loading = false;
        this.tripList = response.data;
        this.tripList = this.tripList.map((data) => {
          return { ...data, driver: "" };
        });
        if (this.tripList.length > 0) {
          this.sortData(this.tripList);
        }
      }, 500);
    },
    copyMessage(data) {
      let text = "";
      if (data.driverDetails !== null) {
        text = `Customer Name: ${data.user}\nMobile: ${data.mobile}\nLocation: ${data.source}\nTime: ${moment(data.pickUpTime, "x").format("DD-MM-YYYY LT")}\nBranch: ${data.branch}\nDriver: ${
          data.driverDetails.name
        }\nDriver Mobile: ${data.driverDetails.mobile}\nCar No.: ${data.vehicle}`;
      } else {
        text = `Customer Name: ${data.user}\nMobile: ${data.mobile}\nLocation: ${data.source}\nTime: ${moment(data.pickUpTime, "x").format("DD-MM-YYYY LT")}\nBranch: ${data.branch}`;
      }
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$toasted.show("Details copied", {
        position: "bottom-center",
        duration: 3000,
      });
    },
    reloadData() {
      if (this.filters === "") {
        this.getTripData({ date: this.date });
      } else {
        this.getTripData(this.filters);
      }
    },
    getTime(data) {
      return moment(data, "x").format("MMM DD");
    },
    getTimer(data) {
      return moment(data, "x").format("LT");
    },
    sortData(data) {
      data.sort((a, b) => {
        return (a.driverDetails !== null) - (b.driverDetails !== null) || -(a > b) || +(a < b);
      });
    },
    async getDriverDetails(data, index) {
      if (data.driver.length === 10) {
        this.tripId = data.bigId;
        this.tripIndex = index;
        this.bigId = data.bigId;
        const response = await axios.post("trip/driver/details", { mobile: data.driver, tripId: data.bigId });
        if (response.data.driverDetails) {
          if (response.data.driverDetails.trash) {
            this.message = "This driver is Inactive";
            return (this.dialogView = true);
          }
          this.driverNumber = data.driver;
          if (response.data.message !== "No driver exist in this number" && response.data.message !== "No vehicle assigned") {
            this.assignDialog = true;
            this.driverDetails = response.data.driverDetails;
            this.currentCity = response.data.currentCity;
            this.currentState = response.data.currentState;
          } else {
            this.dialogView = true;
            this.driverNumber = data.driver;
            this.message = response.data.message;
          }
        } else if (response.data.message === "No driver exist in this number") {
          this.dialogView = true;
          this.driverNumber = data.driver;
          this.message = response.data.message;
        } else if (response.data.message === "Driver exists for another servicePartner") {
          this.dialogView = true;
          this.driverNumber = data.driver;
          this.message = "Driver exists for another vendor, please contact taSki to assign the trip to this driver";
        }
      }
    },
    showDetails(data) {
      this.drawer = true;
      this.tripId = data;
    },
    driverAssign(data) {
      this.tripList[this.tripIndex].driverDetails = { name: data.name, mobile: data.number };
      this.tripList[this.tripIndex].vehicle = data.vehicleNo;
      this.assignDialog = false;
      this.tripList[this.tripIndex].driver = "";
      this.tripList[this.tripIndex].status = { id: 4, status: "Driver Assigned" };
      this.sortData(this.tripList);
    },
    driverCabAssign(data) {
      this.tripList[this.tripIndex].driverDetails = { name: data.name, mobile: data.number };
      this.tripList[this.tripIndex].vehicle = data.vehicleNo;
      this.addDialogStatus = false;
      this.tripList[this.tripIndex].driver = "";
      this.tripList[this.tripIndex].status = { id: 4, status: "Driver Assigned" };
      this.sortData(this.tripList);
    },
    closeAssignDialog() {
      this.assignDialog = false;
      this.tripList[this.tripIndex].driver = "";
    },
    closeAddDialog() {
      this.addDialogStatus = false;
      this.tripList[this.tripIndex].driver = "";
    },
    closeVendorDialog() {
      this.dialogView = false;
      const index = this.tripList.findIndex((data) => data.driver === this.driverNumber);
      if (index !== -1) {
        this.tripList[index].driver = "";
      }
    },
    async usAssignDriver(data, index) {
      let confirmTitle = "Confirm Un-Assign";
      let confirmText = "Please confirm to Un-Assign this Driver";
      if (await this.$refs.confirm.open(confirmTitle, confirmText, {})) {
        this.loading = true;
        try {
          const response = await axios.post("trip/unassign", { tripid: data.bigId });
          if (response.status === 200) {
            this.reloadData();
            this.loading = false;
            const index = this.tripList.findIndex((trip) => trip.bigId === data.bigId);
            if (index !== -1) {
              this.tripList[index].driverDetails = null;
              this.tripList[index].status = { id: 2, status: "Approved" };
              this.$toasted.show(response.data.message, {
                position: "bottom-center",
                duration: 4000,
              });
              this.loading = false;
            }
          }
        } catch (error) {
          this.loading = false;
        }
      }
    },
    startTrip(data, index) {
      this.startDialog = true;
      this.statusBigId = data.bigId;
      if (data.status.id === 4 || data.status.id === 5 || data.status.id === 6) {
        this.status = "Start";
      }
    },
    closeDialog() {
      this.startDialog = false;
    },
    startEndTrip(data) {
      const index = this.tripList.findIndex((trip) => trip.bigId === data.id);
      if (index !== -1) {
        this.tripList.splice(index, 1);
      }
      this.startDialog = false;
    },
    getTripInfo(data) {
      this.remarksModel = !this.remarksModel;
      this.tripInfo.tripId = data.bigId;
      this.tripInfo.status = data.status.id;
    },
    async rejectTrip(remarks) {
      this.loading = true;
      try {
        let payload = {
          tripId: this.tripInfo.tripId,
          status: this.tripInfo.status,
          sp: localStorage.getItem("vendorName"),
          remarks: remarks,
        };
        const response = await axios.post("/trip/reject", payload);
        if (response.status === 200) {
          this.remarksModel = false;
          this.loading = false;
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
          this.reloadData();
        } else {
          this.loading = false;
          this.$toasted.show(response.data, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      } catch (error) {
        this.loading = false;
        this.$toasted.show(error.response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./tobeAssigned.scss";
</style>
