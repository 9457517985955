<template>
  <div class="main-inner-blk">
    <v-card class="driver-filter-blk" elevation="3">
      <Filters :placeHolder="'Search Driver Name/Number'" @get-filter="getFilters"></Filters>
    </v-card>

    <div class="driver-modal">
      <div class="driver-list-modal">
        <DriverDataList
          :driverList="driverList"
          @select-driver="selectDriver"
          @add-driver="addDriver"
          :id="driverDetails.token ? driverDetails.token : ''"
          :page="page"
          :items="items"
          @insert-driver="insertDriver"></DriverDataList>
      </div>
      <div class="driver-content-modal">
        <v-tabs background-color="black" color="#facc15" slider-color="#facc15" dark v-model="tabs">
          <v-tab href="#profile-tab" :disabled="tabs === 'insert-tab'">Details</v-tab>
          <v-tab href="#insert-tab" v-if="tabs === 'insert-tab'">{{ buttonName }} Driver</v-tab>
          <v-tab href="#document-tab" :disabled="tabs === 'insert-tab'">Documents</v-tab>
          <v-tab href="#list-tab" :disabled="tabs === 'insert-tab'">Remarks</v-tab>

          <v-tabs-items v-model="tabs" class="driver-tab-items">
            <v-tab-item id="insert-tab">
              <DriverInsert
                @insert-driver="insertDriver"
                @cancel-insert="cancelInsert"
                :tabName="tabs"
                :driverDetails="driverDetails"
                :editStatus="editStatus"
                @driver-updated="getDriverDetails({ loadList: true })"></DriverInsert>
            </v-tab-item>
            <v-tab-item id="profile-tab">
              <DriverProfile :driverDetails="driverDetails" @assign-vehicle="assignVehicle" @edit-driver="editDriver" @driver-updated="getDriverDetails({ loadList: true })"></DriverProfile>
            </v-tab-item>
            <v-tab-item id="document-tab">
              <DocumentInsert :driverDetails="driverDetails"></DocumentInsert>
            </v-tab-item>
            <v-tab-item id="list-tab">
              <DriversRemarksList :driverDetails="driverDetails"></DriversRemarksList>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </div>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import DriverDataList from "@/components/driver-management/driver-data-list/DriverDataList";
import DriverInsert from "@/components/driver-management/driver-insert/DriverInsert";
import axios from "@/axios/axios-global";
import DriverProfile from "@/components/driver-management/driver-profile/DriverProfile";
import Loading from "@/components/common/Loading";
import DocumentInsert from "@/components/driver-management/document-insert/DocumentInsert";
import DriversRemarksList from "../drivers-remarks-list/DriversRemarksList.vue";

export default {
  name: "DriverDashboard",
  components: { DocumentInsert, Loading, DriverProfile, DriverInsert, DriverDataList, Filters, DriversRemarksList },
  data: () => {
    return {
      driverList: [],
      tabs: "profile-tab",
      page: 1,
      items: 20,
      editStatus: false,
      loading: false,
      buttonName: "ADD",
      filters: "",
      driverDetails: {},
    };
  },
  async mounted() {
    await this.getDriverList({ searchValue: "", initial: true });
    this.getDriverDetails({ loadList: false });
  },
  methods: {
    getFilters(data) {
      this.filters = data;
      this.filters.token = "";
      this.getDriverList({ searchValue: "", initial: false });
      this.getDriverDetails({ loadList: false });
    },
    async getDriverDetails(data) {
      if (data.loadList) {
        await this.getDriverList({ searchValue: "", initial: false });
      }
      if (this.driverDetails.token) {
        this.loading = true;
        const response = await axios.post("driver/details", { driverId: this.driverDetails.token });
        this.loading = false;
        this.driverDetails = response.data;
      }
      this.tabs = "profile-tab";
    },
    async getDriverList(data) {
      this.loading = true;
      const response = await axios.post("driver/list", { search: data.searchValue ? data.searchValue : "", page: this.page, items: this.items });
      this.loading = false;
      this.driverList = this.page === 1 ? response.data : [...this.driverList, ...response.data];
      if (this.driverList.length > 0) {
        if (data.initial) {
          this.driverDetails = this.driverList[0];
        } else {
          this.driverDetails = this.driverList.find((driver) => driver.token === this.driverDetails.token);
        }
      }
    },
    insertDriver(data) {
      this.driverList.unshift(data);
      this.tabs = "profile-tab";
      this.driverDetails = data;
    },
    selectDriver(data) {
      this.tabs = "profile-tab";
      this.driverDetails = data;
      this.getDriverDetails({ loadList: false });
    },
    addDriver() {
      this.tabs = "insert-tab";
      this.editStatus = false;
      if (this.buttonName === "Edit") {
        this.tabs = "profile-tab";
        setTimeout(() => {
          this.tabs = "insert-tab";
        }, 0);
        this.buttonName = "ADD";
      }
    },
    cancelInsert() {
      this.tabs = "profile-tab";
    },
    assignVehicle() {
      this.driverDetails = {};
    },
    editDriver() {
      this.tabs = "insert-tab";
      this.editStatus = true;
      this.buttonName = "Edit";
    },
    // loadMore() {
    //   this.page++;
    //   this.getDriverList({ searchValue: "", token: "" });
    // },
  },
};
</script>

<style scoped lang="scss">
@import "./driverDashboard";
</style>
