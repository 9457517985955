<template>
  <div class="cab-profile-modal">
    <div class="cab-profile-tab--blk" v-if="vehicleDetails.token && vehicleDetails.details">
      <div class="cab-details-tab-modal">
        <v-card class="cab-profile-blk" elevation="2">
          <div class="vehicle-details-modal">
            <div class="vehicle-no-blk text-center">
              <span>{{ vehicleDetails.vehicleno }}</span>
            </div>
            <div class="manufacturer-blk text-center">
              <span>{{ vehicleDetails.details.manufacturer.name }}</span>
            </div>
            <div class="manufacturer-blk text-center">
              <span>{{ vehicleDetails.details.model.name }}</span>
            </div>
            <div class="manufacturer-blk text-center">
              <span>{{ vehicleDetails.category.name }}</span>
            </div>
            <div class="manufacturer-blk text-center">
              <span>{{ vehicleDetails.details.color.name }}</span>
            </div>
            <div class="manufacturer-blk text-center">
              <span>{{ vehicleDetails.details.year }}</span>
            </div>
            <div class="manufacturer-blk text-center" v-for="(data, index) in vehicleDetails.details.fuel" :key="index">
              <span>{{ data.name }}</span>
            </div>
            <div class="driver-blk text-center" v-if="vehicleDetails && vehicleDetails.drivers && vehicleDetails.drivers.length > 0">
              <div v-for="(driver, index1) in vehicleDetails.drivers" :key="driver.id">
                <span>{{ driver.name }}<b v-if="index1 !== vehicleDetails.drivers.length - 1">,</b></span>
              </div>
            </div>
            <div v-if="vehicleDetails && vehicleDetails.drivers && vehicleDetails.drivers.length === 0" class="text-center no-driver-blk">
              <span>No driver Assigned</span>
            </div>
            <div class="edit-button-modal">
              <v-btn @click="remarksModel = true" :color="vehicleDetails.trash ? 'green' : '#ed5e5e'">{{ !vehicleDetails.trash ? "Deactivate" : "Activate" }}</v-btn>
              <v-btn color="#e9bd00" @click="editVehicle">Edit</v-btn>
            </div>
            <Confirm ref="confirm"></Confirm>
            <Loading v-if="loading"></Loading>
            <DeactivationModal
              v-if="remarksModel"
              :title="vehicleDetails.trash ? 'Activate' : 'Deactivate'"
              :show.sync="remarksModel"
              :onConfirm="vehicleDetails.trash ? activateVehicle : deactivateVehicle">
            </DeactivationModal>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else class="no-vehicle-profile">
      <span>No Data Available</span>
    </div>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
import Loading from "@/components/common/Loading";
import Confirm from "@/components/common/Confirm";
import DeactivationModal from "../../modal/DeactivationModal.vue";
export default {
  name: "VehicleProfile",
  components: { Confirm, Loading, DeactivationModal },
  props: {
    vehicleDetails: Object,
  },
  mounted() {},
  data: () => {
    return {
      loading: false,
      remarksModel: false,
    };
  },
  methods: {
    editVehicle() {
      this.$emit("edit-vehicle");
    },
    async activateVehicle(remarks) {
      if (!remarks) {
        return this.$toasted.show("Remarks required", {
          position: "bottom-center",
          duration: 4000,
        });
      }
      this.loading = true;
      const response = await axios.patch("vehicle/activate", { vehicleId: this.vehicleDetails.token, reason: remarks, user: localStorage.getItem("vendorName") });
      if (response.status === 200) {
        this.$emit("vehicle-updated", this.vehicleDetails);
        this.loading = false;
        this.remarksModel = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      } else {
        this.loading = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },
    async deactivateVehicle(remarks) {
      if (!remarks) {
        return this.$toasted.show("Remarks required.", {
          position: "bottom-center",
          duration: 4000,
        });
      }
      this.loading = true;
      const response = await axios.patch("/vehicle/deactivate", {
        vehicleId: this.vehicleDetails.token,
        reason: remarks,
        user: localStorage.getItem("vendorName"),
      });
      if (response.status === 200) {
        this.loading = false;
        this.remarksModel = false;
        this.$emit("vehicle-updated", this.driverDetails);
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      } else {
        this.loading = false;
        this.$toasted.show(response.data, {
          position: "bottom-center",
          duration: 4000,
        });
      }
    },

    closeModal() {
      this.remarksModel = false;
      this.deactivateReason = "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "./vehicleProfile";
</style>
