<template>
  <div class="document-insert-content-modal">
    <div class="document-upload-modal" v-if="vehicleId !== 0">
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="rcUpload" @change="insertRc" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.rcUpload.click()" v-if="rcImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="rcImage !== ''">
          <img :src="rcImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>RC Book Image</span>
          <v-btn small color="#83b9e5" @click="$refs.rcUpload.click()" v-if="rcImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="interiorProof" @change="insertInterior" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.interiorProof.click()" v-if="interiorImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="interiorImage !== ''">
          <img :src="interiorImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Interior Image</span>
          <v-btn small color="#83b9e5" @click="$refs.interiorProof.click()" v-if="interiorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="exteriorProof" @change="insertExterior" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.exteriorProof.click()" v-if="exteriorImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="exteriorImage !== ''">
          <img :src="exteriorImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Exterior Image</span>
          <v-btn small color="#83b9e5" @click="$refs.exteriorProof.click()" v-if="exteriorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="insuranceProof" @change="insertInsurance" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.insuranceProof.click()" v-if="insuranceImage === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="insuranceImage !== ''">
          <img :src="insuranceImage" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Insurance</span>
          <v-btn small color="#83b9e5" @click="$refs.insuranceProof.click()" v-if="insuranceImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>

      <v-card class="photo-insert-modal" elevation="2">
        <input accept="image/*" ref="carriagePermitProof" @change="insertCarriagePermit" type="file" class="input-blk" />
        <div class="image-upload-modal" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit === ''">
          <i class="fas fa-images"></i>
          <span>Upload Image</span>
        </div>
        <div class="image-upload-modal" v-if="carriagePermit !== ''">
          <img :src="carriagePermit" class="image-view-blk" />
        </div>
        <div class="image-name-blk">
          <span>Carriage Permit</span>
          <v-btn small color="#83b9e5" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit !== ''">Edit<i class="fas fa-pen"></i></v-btn>
        </div>
      </v-card>
    </div>
    <div v-else class="no-vehicle-document">
      <span>No Data Available</span>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";
export default {
  name: "DocumentInsert",
  props: {
    vehicleDetails: Object,
  },
  data: () => {
    return {
      rcImage: "",
      interiorImage: "",
      exteriorImage: "",
      insuranceImage: "",
      carriagePermit: "",
      baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",
      vehicleDocument: "",
    };
  },
  mounted() {
    this.getVehicleDetails();
  },
  methods: {
    async getVehicleDetails() {
      if (this.vehicleDetails.token) {
        this.loading = true;
        const response = await axios.post("vehicle/details", { vehicleId: this.vehicleDetails.token });
        this.loading = false;
        this.vehicleDocument = response.data.document;
        this.rcImage = this.vehicleDocument.rcbook.image;
        this.interiorImage = this.vehicleDocument.interior.image;
        this.exteriorImage = this.vehicleDocument.exterior.image;
        this.insuranceImage = this.vehicleDocument.insurance.image;
        this.carriagePermit = this.vehicleDocument.carriagepermit.image;
      }
    },
    async insertRc(event) {
      let files = event.target.files[0];
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "rcBook",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          this.rcImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertInterior(event) {
      let files = event.target.files[0];
      // this.idImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "interior",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          this.interiorImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    // async insertPan(event) {
    //   let files = event.target.files[0]
    //   this.panImage = URL.createObjectURL(files)
    // },
    async insertInsurance(event) {
      let files = event.target.files[0];
      // this.licenceFrontImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "insurance",
          // licenceNo: this.licenceNo,
          // expData: moment(this.expDate).format('YYYYMMDD'),
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          this.insuranceImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertExterior(event) {
      let files = event.target.files[0];
      // this.licenceBackImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "exterior",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          this.exteriorImage = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
    async insertCarriagePermit(event) {
      let files = event.target.files[0];
      // this.policeClearanceImage = URL.createObjectURL(files)
      if (event.target.files.length > 0) {
        let formData = new FormData();
        const payload = {
          vehicleId: this.vehicleDetails.token,
          imageName: "carriagePermit",
        };
        formData.append("data", JSON.stringify(payload));
        formData.append("image", files);
        const response = await axios.patch("vehicle/document/update", formData);
        if (response.status === 200) {
          this.carriagePermit = response.data.url;
          this.$toasted.show(response.data.message, {
            position: "bottom-center",
            duration: 4000,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/components/vehicle-management/vehicle-document/vehicleDocument";
</style>
